<template>
	<div class="newaddressbox" :class="themeClass">
		<!-- <el-tabs v-model="activeName" @tab-click="handleClick" class="News-n">
			<el-tab-pane label="通知信息" name="first"></el-tab-pane>
			<el-tab-pane label="公告信息" name="second"></el-tab-pane>
			<el-tab-pane label="邮件信息" name="third"></el-tab-pane>
		</el-tabs> -->
		<!-- tab切换 -->
		<!-- 	公告通知box -->
		<el-dialog :visible.sync="noticeShow" title="公告消息" width="40%" height="10px !important;"
			@close="closeNoticeShow" :close-on-click-modal="false" :close-on-press-escape="false" class="homeDialog"
			:show-close="false">
			<div class="notice-content position-relative">
				<div class="close-notice-btn pointer noticeclos position-absolute" @click="closeNoticeShow"><i
						class="el-icon-close font-color-000 font-size30" ></i></div>
				<div class="flex-row-center-center margin-t-10 margin-b-10">
					<div class="fTitle fontSize16">{{gg.fTitle}}</div>
				</div>
				<div class="notice-content-item">

					<div class="margin-b-15 overscore fontSize15">
						<RichTextStyle :html="gg.fDesc + ``" :styleRule="rule" />
					</div>
					<div class="flex-row-space-between font-size12">

						<div class="text-right">
							<div class="margin-tb-8 fontSize15">{{ gg.fCreator }}</div>
							<div class="fontSize15">{{ gg.fCreateTime }}</div>
						</div>
					</div>
					<div class="flex-row-center-center margin-t-10">
						<el-button @click="closeNoticeShow">关闭</el-button>
					</div>

				</div>
			</div>
		</el-dialog>

		<!-- tab切换 -->
		<!-- 	公告通知box -->
		<el-dialog :visible.sync="noticeShow1" title="通知消息" width="40%" height="10px !important;"
			@close="closeNoticeShow" :close-on-click-modal="false" :close-on-press-escape="false" class="homeDialog"
			:show-close="false">
			<div class="notice-content position-relative">
				<div class="close-notice-btn pointer noticeclos position-absolute" @click="closeNoticeShow1"><i
						class="el-icon-close font-color-000 font-size30"></i></div>
				<div class="flex-row-center-center margin-t-10 margin-b-10">
					<div class="fTitle fontSize16">{{gg.fTitle}}</div>
				</div>
				<div class="notice-content-item">

					<div class="margin-b-15 overscore fontSize15">
						<RichTextStyle :html="gg.fDesc + ``" :styleRule="rule" />
						<!-- <div v-html="gg.FMessageContent"></div> -->
					</div>
					<div class="flex-row-space-between font-size12">

						<div class="text-right">
							<div class="margin-tb-8 fontSize15">{{gg.fCreator}}</div>
							<div class="fontSize15">{{ gg.fSendTime }}</div>
						</div>
					</div>
                   <div class="flex-row-center-center margin-t-10">
                   	<el-button @click="closeNoticeShow1">关闭</el-button>
                   </div>
				</div>
			</div>
		</el-dialog>

		<div class="flex-row-space-between">
			<div class="pointer newaddressboxs margin-b-10" style="margin-top: 13px;margin-left: 13px;">
				<div class="newaddressbox1" v-for="(item,index) in tabData" :key="index"
					:class="item.checked?'checkTitleSty':''" @click="selecttab(index)">
					{{item.title}}
				</div>
			</div>
			<div style="margin:20px; 30px 0px 0px">
				<el-button plain @click="clickRead">全部标记为已读</el-button>
			</div>
		</div>
		<!-- 通知信息 -->
		<div v-if="listShow == 1">
			<div class="rowboxcontent padding-lr-15">
				<div class="rowlist margin-lr-15 " v-for="(item, index) in listData" :key="index"
					:data-index="index" @click="opentongzhi1(item)">
					<!-- <div class="list-radio" v-if="bulkDelete == true">
						<el-radio class="radioleft" :checked="item.checked" :value="index.toString()" style="transform:scale(0.6)" /></el-radio>
					</div> -->
					<!-- 0是未读，1是已读， -->
					<div class="carInfor u-flex-11" @click="tabbarDelete(item)">
						<div class="flex-row-space-between-center">
							<div class="flex-row-align-center line-height-54" style="font-size: 14px;font-weight: bold;" :class="[item.fState==0?'new1':'navactive']">
								<!--  @clickS="dialogVisibles(item)" -->
								<div class="el-icon-folder margin-r-5" v-if="item.fState ==0"></div>
								<div class="el-icon-folder-opened
 margin-r-5" v-if="item.fState == 1"></div>{{item.fTitle}}
							</div>
							<div class="carname">
								<p class="new3 margin-r-5">{{item.fCreateTime}}</p>
								<p class="new2 pointer"></p>
								<div class="u-flex u-row-right margin-r-5" slot="right">
									<div class="new3 pointer"  @click.stop="changeStatus(item)"> {{ item.fState == 0 ? '标记为已读' : '已读'}} </div>
								</div>
								<el-tooltip class="item margin-r-5" effect="dark" content="删除" placement="top">
								<div class="el-icon-delete pointer new3" style="font-size: 16px;"
									
									@click.stop="detelbtn(item)">
								</div>
								</el-tooltip>
							</div>
						</div>
					</div>
					<!-- <el-divider></el-divider> -->
				</div>
			</div>
			<svg-icon style="width:100%;" class="flex-row-center-center" icon-class="NoNews"
				v-if="listData.length == 0" />
			<div class="flex-row-center-flex-end margin-t-20 margin-b-30 padding-lr-15">
				<pagination @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"
					:currentPage="currentPage" :pagesize="pagesize" :total="total"></pagination>
			</div>
		</div>
		<!-- 公告信息 -->
		<div v-if="listShow == 0">
			<div class="rowboxcontent padding-lr-15">
				<div class="rowlist margin-lr-15" v-for="(item, index) in listData1" :key="index"
					:data-index="index" @click="opentongzhi(item)">
					<!-- <div class="list-radio" v-if="bulkDelete == true">
						<el-radio class="radioleft" :checked="item.checked" :value="index.toString()" style="transform:scale(0.6)" /></el-radio>
					</div> -->
					<div class="carInfor u-flex-11" @click="tabbarDelete(item)">
						<div class="flex-row-space-between-center">
							<div class="flex-row-align-center line-height-54 " style="font-size: 14px;font-weight: bold;"  :class="[item.fState ==0?'new1':'navactive']">
								<div class="el-icon-folder margin-r-5" style="font-weight: bold;"
									v-if="item.fState ==0"></div>
								<div class="el-icon-folder-opened margin-r-5" v-if="item.fState ==1"></div>
								{{item.fTitle}}
							</div>
							<div class="carname">
								<p class="new3 margin-r-5">{{item.fCreateTime}}</p>
								<p class="new2 pointer"></p>
								<div class="u-flex u-row-right margin-r-5" slot="right">
									<div class="new3 pointer" @click.stop="changeStatus(item)"> {{ item.fState == 0 ? '标记为已读' : '已读'}} </div>
								</div>
								<el-tooltip class="item margin-r-5" effect="dark" content="删除" placement="top">
								<div class="el-icon-delete pointer new3" style="font-size: 16px;"
									
									@click.stop="detelbtn(item)">
								</div>
								</el-tooltip>
							</div>
						</div>
					</div>
					<!-- <el-divider></el-divider> -->
				</div>
			</div>
			<!-- <div  style="width: 850px;height: 900px;" v-if="listData1.length == 0">
				<svg-icon icon-class="NoNews" />
			</div> -->
			<svg-icon style="width:100%;" class="flex-row-center-center" icon-class="NoNews"
				v-if="listData1.length == 0" />
			<div class="flex-row-center-flex-end margin-b-30 padding-lr-15">
				<pagination @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChanges"
					:currentPage="currentPages" :pagesize="pagesizes" :total="totals"></pagination>
			</div>
		</div>
		<!-- 消息弹出层 -->
		<el-dialog title="消息信息" :title="this.AlllistData.fTitle" :visible.sync="dialogVisible" width="30%">
			<!-- <img :src="item.img" class="PopUp-img"> -->
			<!-- <span class="PopUp-title">{{this.AlllistData.fTitle}}</span> -->
			<p class="PopUp-content">{{this.AlllistData.fDesc}}</p>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" @click="dialogVisiblee">取 消</el-button>
				<el-button size="small" type="primary" class="background-color-theme" @click="dialogVisible = false">确 定
				</el-button>
				<!-- <el-button @click="dialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="dialogVisible = false">确 定</el-button> -->
			</span>
		</el-dialog>
	</div>
	</div>
</template>

<script>
	import {
		mapGetters,
		mapActions
	} from "vuex";
	import RichTextStyle from '@/components/richtext/RichTextStyle';
	import pagination from '@/components/pagination/pagination.vue';
	export default {
		data() {
			return {
				//分页数据
				total: 0, //总条数
				pagesize: 12, //每页显示条目个数
				currentPage: 1, //当前页

				//分页数据1
				totals: 0, //总条数
				pagesizes: 12, //每页显示条目个数
				currentPages: 1, //当前页

				listShow: 0,
				fRecordID: [],
				tabData: [
					{
						title: '公告信息',
						type: 1,
						checked: true
					},
					{
						title: '通知信息',
						type: 2,
						checked: false,
					},
					// {
					// 	title: '邮件信息',
					// 	type: 3,
					// 	checked: false
					// },
				],
				rule: [],
				keyword: '',
				listData1: [],
				gg: {},
				bulkDelete: 1,
				noticeShow: false, //判断公告显示不显示
				noticeShow1: false,
				dialogVisible: false,
				// radioAllChecked: false,
				activeName: 'second',
				// show:true,
				AlllistData: {},
				// 	{
				// 	img: require('../../assets/imgs/NoCar.png'),
				// 	titles: '通知信息通知信息通知信息通知信息',
				// 	content: '信息内容信息内容信息内容信息内容信息内容信息内容信息内容信息内容信息内容信息内容信息内容信息内容信息内容信息内容信息内容信息内容信息内容信息内容信息内容信息内容信息内容信息内容信息内容信息内容信息内容信息内容信息内容'
				// },
				// ],
				listData: [
					// {
					// 	name: 'one',
					// 	id: 1,
					// 	title: ' 通告通知标题标题',
					// 	content: '这里是邮件的标题，例如：通知您的账户还款能力 大到限定标准，将为您寄出一款最新型的POS机',
					// 	all: '2020-11-17 下午 19:23',
					// 	show: true,
					// },
				],
				listDataId:[],

			}
		},
		onLoad() {


		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		components: {
			RichTextStyle,
			pagination
		},
		mounted() {
			let string = "home"
			let string1 = this.$route.params.shownotice
			console.log(string, string1)
			if (string == string1) {
				for (let item of this.tabData) {
					item.checked = false;
				}
				this.tabData[0].checked = true;
				this.listShow = 0
				this.Announcementnformation()
			} else {
				for (let item of this.tabData) {
					item.checked = false;
				}
				this.tabData[0].checked = true;
				this.listShow = 0
				this.Announcementnformation()
			}

		},

		methods: {
			clickRead(){
				console.log(this.listShow)
				if(this.listShow == 0){
					this.listData1.forEach((item)=>{
						console.log(item)
						let dataId = {
						  id:item.fRecordID
						}
						this.listDataId.push(dataId)
					})
					console.log(this.listDataId)
					
				}else if(this.listShow == 1){
					this.listData.forEach((item,index)=>{
						let dataId = {
						  id:item.fRecordID
						}
						this.listDataId.push(dataId)
					})
				}
				this.ApiRequestPutNOMess('api/mall/ebbase/announcement-notice-record/update-list',this.listDataId
				).then(result=>{
					this.getCount()
					this.Announcementnformation();
					this.Announcementnformations();
					this.listDataId=[]
				})
			},
			...mapActions(["setCount"]),
			changeStatus(val){
				// console.log(val)
				val.fState = 1;
				//点击标记已读（需要再次调用接口，否则刷新完这条数据状态还是未读）
				this.tabbarDelete(val)
			},
			getCount() {
				this.ApiRequestPost('api/mall/ebbase/announcement-notice-record/get-curret-user-record-count', {
					
				}).then(
					result => {
						this.$store.commit('setCount',result.obj.fCount)
					},
					error => {
						// this.tableLoading = false;
					}
				)
			},
			//鼠标悬停显示
			funMouseOver(item,index) {
				  this.$message({
				            message: '确定删除吗',
				            type: 'warning'
				          });
			},
			funMouseOut(item) {
				// item.delShow = false
			},
			handleSizeChange() {

			},
			showHtml(str) {
				if (str) {
					return str
						.replace(str ? /&(?!#?\w+;)/g : /&/g, '&amp;')
						.replace(/&lt;/g, "<")
						.replace(/&gt;/g, ">")
						.replace(/&quot;/g, "\"")
						.replace(/&#39;/g, "\'")
						.replace(/&amp;nbsp;/g, '\u3000')
				}

			},
			opentongzhi(e) {
				console.log(e)
				this.gg = e;
				this.gg.fDesc = this.showHtml(e.fDesc)
				this.gg.fCreateTime = this.getDate(this.gg.fCreateTime)
				this.noticeShow = true
			},
			opentongzhi1(item) {
				console.log('item客商通知',item)
				this.gg = item
				this.noticeShow1 = true
				console.log('gg', this.gg)
			},
			closeNoticeShow() {
				this.noticeShow = false
			},
			closeNoticeShow1() {
				this.noticeShow1 = false
			},
			dialogVisiblee() {
				console.log(11111)
				this.dialogVisible = false;
			},
			//tab点击事件
			selecttab(e) {
				// console.log(e,'111111');
				for (let item of this.tabData) {
					item.checked = false;
				}
				this.tabData[e].checked = true;
				this.listShow = e;
				if (this.listShow == 1) {
					this.Announcementnformations();
				} else if (this.listShow == 0) {
					this.Announcementnformation()
				}
			},
			handleCurrentChanges(val) {
				this.currentPages = val;
				this.Announcementnformation();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.Announcementnformations();
			},
			//公告信息
			Announcementnformation() {
				this.ApiRequestPost('/api/mall/ebbase/announcement-notice-record/get-more-notice-gg-page', {
					maxResultCount: this.pagesizes,
					skipCount: (this.currentPages - 1) * this.pagesizes,
					filter: "",
					sorting: "",
				}).then(
					result => {
						console.log(result, '公告信息1111111111');
						this.listData1 = result.obj.items
						for (let item of result.obj.items) {
							item.fCreateTime = this.getDate(item.fCreateTime);
							item.delShow = false
						}
						
						this.totals = result.obj.totalCount
					},
					rej => {

					}
				);
			},
			// 获取富文本规则
			// getRule(item) {
			// 	console.log(item);
			// 	if (item.useRules) {
			// 		if (item.useRules.length > 0) {
			// 			return item.useRules[0].fUseDescribe;
			// 		}
			// 	}
			// 	return '';
			// },
			//通知信息
			Announcementnformations() {
				this.ApiRequestPost('/api/mall/ebbase/announcement-notice-record/get-more-notice-tz-page', {
					maxResultCount: this.pagesize,
					skipCount: (this.currentPage - 1) * this.pagesize,
					filter: "",
					sorting: "",
				}).then(
					result => {
						console.log(result, '通知信息1111111111');
						this.listData = result.obj.items
						// this.listData.fRecordID = result.obj[0].fRecordID
						for (let item of result.obj.items) {
							item.fCreateTime = this.getDate(item.fCreateTime);
							// item.fSendTime = this.getTime(item.fSendTime)
							// console.log(item.fRecordID,"item.fRecordID")
							// var newlist=[]
							// item.fRecordID.forEach(item=>{
							// 	 newlist.push(item)
							// })
							// console.log(newlist)
						}
						this.total = result.obj.totalCount
						// var newlist=[]
						// this.item.fRecordID.forEach(item=>{
						// 	 newlist.push(item)
						// })
						// console.log(newlist)
					},
					rej => {

					}
				);
			},
			//消息内容弹框
			dialogVisibles(item) {
				// console.log('item',item)
				// this.dialogVisible = true;
				// this.ApiRequestPost('api/mall/auxiliary/message-send-record/message-read', {
				// 	id: item.fRecordID
				// }).then(
				// 	result => {
				// 		console.log(result, "   bbbbbbb")
				// 		// this.AlllistData = result.obj
				// 		// console.log(this.AlllistData)
				// 		this.AlllistData.fTitle = result.obj.fTitle
				// 		this.AlllistData.fDesc = result.obj.fDesc
				// 	},
				// 	rej => {

				// 	}
				// );
			},
			//消息点击已读
			tabbarDelete(item) {
				// this.dialogVisible = true;
				let content = item.fDesc
				console.log(content)
				this.AlllistData = content
				console.log('item', item)
				let fMessageID = item.fRecordID

				this.ApiRequestPutNOMess('api/mall/ebbase/announcement-notice-record/update-list', [{
						id: fMessageID,
					}]

				).then(
					result => {
						this.getCount()
						this.bulkDelete = !this.bulkDelete;
						this.Announcementnformation();
						this.Announcementnformations();
						console.log('result', result)
						console.error(item)
					},
					rej => {

					}
				);
			},
			handleClose(done) {
				this.$confirm('确认关闭？')
					.then(_ => {
						done();
					})
					.catch(_ => {});
			},
			change(index) {
				this.current = index;
			},
			handleClick(tab, event) {
				console.log(tab, event);
			},
			//删除
			detelbtn(item, index) {
				let arr = [{
					fRecordID: item.fRecordID
				}];
				this.$confirm('要删除信息, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.ApiRequestPost('/api/mall/ebbase/announcement-notice-record/delete-list', arr).then(
						result => {
							this.getCount()
							this.Announcementnformations();
							this.Announcementnformation();
						},
						rej => {}
					);
				}).catch(() => {
					// this.$message({type: 'info',message: '已取消删除',showClose: false,});          
				});
			},
		}
	}
</script>

<style lang="scss" scoped>
	/*滚动条样式*/
	//         .overscore::-webkit-scrollbar {
	//             width: 4px;    
	//             /*height: 4px;*/
	//         }
	//         .overscore::-webkit-scrollbar-thumb {
	//             border-radius: 10px;
	//             -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
	//             background: rgba(0,0,0,0.2);
	//         }
	//         .overscore::-webkit-scrollbar-track {
	//             -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
	//             border-radius: 0;
	//             background: rgba(0,0,0,0.1);

	//         }

	.read {
		width: 10px;
		height: 10px;
		border: 1px solid #0173fe;
		border-radius: 50%;
	}

	.unread {
		width: 10px;
		height: 10px;
		background-color: #0173fe;
		border-radius: 50%;
	}

	.text-right {
		width: 100%;
		text-align: right;
		// bottom: -72px;
		// position: absolute;
		// right: 0;
	}

	::v-deep.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
		@include themify($themes) {
			color: themed("themes_color") !important;
		}

	}

	::v-deep .el-tabs__item.is-active {
		@include themify($themes) {
			color: themed("themes_color") !important;
		}
	}

	::v-deep .el-tabs__active-bar {
		@include themify($themes) {
			background: themed("themes_color") !important;
		}
	}

	::v-deep .el-tabs__nav .el-tabs__item:hover {
		@include themify($themes) {
			color: themed("themes_color") !important;
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.el-dialog {
		// width: 40%;
		// min-height: 700px !important;
		// height: 700px !important;
	}

	.checkTitleSty {
		font-weight: bolder;
		color: #0173FE;
		border-bottom:2px solid #0173FE ;
	}

	.newaddressbox {
		width: 1100px;
		height: 849px;
		background: #f8f9fe;
		// position: relative;
		// display: flex;
	}

	.newaddressboxs {
		width: 160px;
		background-color: #FFFFFF;
		height: 40px;
		line-height: 40px;
		display: flex;
		justify-content: space-between;
		padding: 0px 13px;
	}

	.News-n {
		width: 260px;
		height: 40px;
		background: #ffffff;
		margin: 0px 0px 10px 17px;
		font-size: 15px;
		font-weight: bold;
		margin-top: 14px;
		padding-left: 10px;
	}

	.codeContent {
		width: 1065px;
		border-radius: 4px;
		background-color: #fff;
		margin-left: 17px;
	}

	.news-time {
		line-height: 70px;
		font-size: 24px;
		font-weight: 400;
		color: #cccccc;
		margin-left: 242px;
	}

	.carname1 {
		display: flex;
		justify-content: space-between;
	}

	.carname1 i {
		font-size: 14px;
		margin-top: 10px;
		color: #000000;
		font-weight: 400;
		// color: #62d9b5;
	}

	.carname {
		display: flex;
		justify-content: space-between;
	}

	.alldelet {
		margin-right: -20px;
	}

	.alldelet:hover {
		color: #0173fe;
	}

	#aa {
		padding-right: 15px;
	}

	.news {
		border-bottom: 2px solid #F2F2F2;
	}

	.new2 {
		font-size: 13px;
		font-weight: 400;
		color: #666666;
		line-height: 24px;
	}

	.navactive {
		font-weight: normal;
		color: #333333;
		// margin-top: 15px;
		// margin-left: 10px;
	}

	.price {
		margin-left: 220px;
	}

	.new3 {
		font-size: 12px;
		font-weight: 400;
		color: #999999;
		line-height: 24px;
		// margin-top: 25px;
	}

	.rowlist {
		background-color: #FFFFFF;
		// padding: 0px 16px;
		border-bottom: 1px solid #F2F2F2;
		// width: 1029px;
	}
   .line-height-54{
   	line-height: 54px;
   }
	.new1 {
		font-weight: bold;
		color: #0173FE;
		// margin-top: 15px;
	}

	.carInfor {
		position: relative;
	}

	.overscore {
		max-height: 400px;
		overflow-y: auto;
	}

	.news-circle {
		width: 5px;
		height: 5px;
		border-radius: 5px;
		background-color: #0173fe;
	}

	.PopUp-title {
		text-align: center;
		font-weight: bolder;
		margin-left: 80px;
	}

	.PopUp-content {
		font-size: 12px;
		text-indent: 2em;
		line-height: 25px;
	}

	.PopUp-img {
		width: 100%;
		height: 200px;

	}
	
	// icon-show div{
	// 	opacity: 1 !important;
	// }
	
	// icon-show:hover div{
	// 	opacity: 1 !important;
	// }

	::v-deep .el-tabs__nav-wrap::after {
		display: none;
	}

	.close-notice-btn {
		top: -80px;
		right: -17px;
		z-index: 201;
	}
	::v-deep .el-button{
		padding: 8px 37px;
	}
	.fontSize16{
		font-size: 16px;
	}
	.fTitle{
	  font-weight: bold;
	}
	/deep/.el-dialog__title {
		font-size: 16px !important;
	}
	.fontSize15{
		font-size: 15px;
	}
	/deep/.el-icon-close:before{
		color: #606266 !important;
		font-weight:normal !important;
	}
</style>
